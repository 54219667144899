export var Routes;
(function(Routes2) {
  Routes2["home"] = "home";
  Routes2["cartRenderCustomerCart"] = "cart-render-customer-cart";
  Routes2["cartAddItem"] = "cart-add-item";
  Routes2["cartAddCustomerInformation"] = "cart-add-customer-information";
  Routes2["postcartAddCustomerInformation"] = "post-cart-add-customer-information";
  Routes2["cartRemoveItem"] = "cart-remove-item";
  Routes2["cartUpdateItem"] = "cart-update-item";
  Routes2["cartGetShippingPrice"] = "cart-shipping-price";
  Routes2["cartEditItem"] = "cart-edit-item";
  Routes2["cartSubmitOrder"] = "cart-submit-order";
  Routes2["cartReviewOrder"] = "cart-review-order";
  Routes2["cartOrderSuccess"] = "cart-order-success";
  Routes2["cartGetShipping"] = "cart-get-shipping";
  Routes2["cartLogin"] = "cart-login";
  Routes2["cartLoginPost"] = "cart-login-post";
  Routes2["cartAddCouponPost"] = "cart-add-coupon-post";
  Routes2["cartRemoveCouponPost"] = "cart-remove-coupon-post";
  Routes2["product"] = "product";
  Routes2["productEditor"] = "product-editor";
  Routes2["productPrice"] = "product-price";
  Routes2["productOptions"] = "product-options";
  Routes2["productConfig"] = "product-config";
  Routes2["productCategory"] = "product-category";
  Routes2["purchaseOrderWebhook"] = "weclapp-purchaseOrder-webhook";
  Routes2["postCreateBatchFolderWebhook"] = "post-create-batch-folder-webhook";
  Routes2["cacheWebhook"] = "weclapp-cache-webhook";
  Routes2["page"] = "page";
  Routes2["module"] = "module";
  Routes2["editorGetFrame"] = "editor-get-frame";
  Routes2["editorGetPreview"] = "editor-get-preview";
  Routes2["editorGetTexture"] = "editor-get-texture";
  Routes2["editorGetTransformed"] = "editor-get-transformed";
  Routes2["presignedUploadPrintTemple"] = "get-presigned-upload-print-template";
  Routes2["getPrintTemplate"] = "get-print-template";
  Routes2["getVariablePrintTemplate"] = "get-variable-print-template";
  Routes2["getPrintThumbnail"] = "get-print-thumbnail";
  Routes2["aboutUs"] = "about-us";
  Routes2["storyblokWebhook"] = "storyblok-webhook";
  Routes2["printTemplates"] = "print-templates";
  Routes2["register"] = "register";
  Routes2["postRegister"] = "post-register";
  Routes2["profile"] = "profile";
  Routes2["profileEdit"] = "profile-edit";
  Routes2["postProfileEdit"] = "post-profile-edit";
  Routes2["createProfile"] = "create-profile";
  Routes2["postCreateProfile"] = "post-create-profile";
  Routes2["postLogin"] = "post-login";
  Routes2["login"] = "login";
  Routes2["logout"] = "logout";
  Routes2["registerSuccess"] = "register-success";
  Routes2["emailVerification"] = "email-verification";
  Routes2["resetPassword"] = "reset-password";
  Routes2["resetPasswordToken"] = "reset-password-token";
  Routes2["postRequestResetPasswordEmail"] = "post-request-reset-password-email";
  Routes2["postRequestResetPasswordEmailAdmin"] = "post-request-reset-password-email-admin";
  Routes2["postResetPassword"] = "post-reset-password";
  Routes2["userTemplates"] = "get-user-templates";
  Routes2["getPublicUserTemplates"] = "get-public-user-templates";
  Routes2["orderConfirmationPDF"] = " get-order-confirmation-pdf";
  Routes2["orderInvoicePDF"] = " get-order-invoice-pdf";
  Routes2["orderShipmentPDF"] = "get-order-shipment-pdf";
  Routes2["editEmail"] = "editEmail";
  Routes2["postEditEmail"] = "post-edit-email";
  Routes2["editEmailVerification"] = "edit-email-verification";
  Routes2["emails"] = "emails";
  Routes2["emailsExport"] = "emails-export";
  Routes2["cacheInvalidate"] = "cache-invalidate";
  Routes2["banners"] = "banners";
  Routes2["addressSuggestions"] = "address-suggestions";
  Routes2["addressLocations"] = "address-locations";
  Routes2["adminKeykenyTransaction"] = "admin-keskeny-transaction";
  Routes2["adminPricingUpdate"] = "admin-pricing-update";
  Routes2["adminPricingUpdatePost"] = "admin-pricing-update-post";
  Routes2["adminPricingRevertPost"] = "admin-pricing-revert-post";
  Routes2["adminPricingRetryPost"] = "admin-pricing-retry-post";
  Routes2["adminSSE"] = "admin-sse";
  Routes2["adminListAccounts"] = "admin-list-accounts";
  Routes2["adminListCarts"] = "admin-list-carts";
  Routes2["adminViewCart"] = "admin-view-cart";
  Routes2["adminHome"] = "admin-home";
  Routes2["adminImpersonate"] = "admin-impersonate";
  Routes2["adminVerifyAccount"] = "admin-verify-account";
  Routes2["maintenance"] = "get-maintenance";
  Routes2["postAddSubmittedOrderToCart"] = "post-addsubmittedordertocart";
  Routes2["postDeleteAccount"] = "post-delete-account";
  Routes2["postOrderFeedback"] = "post-order-feedback";
  Routes2["getCreateAddress"] = "get-create-delivery-address";
  Routes2["getEditAddress"] = "get-edit-address";
  Routes2["postUpsertAddress"] = "post-upsert-address";
  Routes2["deleteDeleteAddress"] = "post-delete-address";
  Routes2["postMarkAs"] = "post-mark-as";
  Routes2["postToggleAs"] = "post-toggle-as";
  Routes2["adminCreateAccount"] = "get-admin-create-account";
  Routes2["postAdminCreateAccount"] = "post-admin-create-account";
  Routes2["adminCoupons"] = "admin-coupons";
  Routes2["adminCouponCreate"] = "admin-coupon-create";
  Routes2["adminCouponCreatePost"] = "admin-coupon-create-post";
  Routes2["adminCouponEdit"] = "admin-coupon-edit";
  Routes2["adminCouponEditPost"] = "admin-coupon-edit-post";
  Routes2["adminCouponCode"] = "admin-coupon-code";
  Routes2["getResendVerifyEmail"] = "get-resend-verify-email";
  Routes2["getNewsletterStatus"] = "get-newsletter-status";
  Routes2["subscribeNewsletter"] = "subscribe-newsletter";
})(Routes || (Routes = {}));
