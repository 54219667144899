import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {t} from "../common/i18n.js";
import {withAttrs} from "../common/utils.js";
import Button from "../components/button.js";
import Stack from "../components/stack.js";
import {colors, fontFamily, media} from "../components/theme.js";
import {Title} from "../components/typo.js";
import App from "../components/app.js";
export default function ErrorPage(props) {
  return /* @__PURE__ */ React.createElement(App, {
    ...props
  }, /* @__PURE__ */ React.createElement(Main, null, /* @__PURE__ */ React.createElement(Error, {
    ...props
  }), /* @__PURE__ */ React.createElement(Img, {
    src: "/assets/images/cat.jpg",
    loading: "lazy"
  })));
}
function Error(props) {
  const {code} = props;
  if (code === 404) {
    return /* @__PURE__ */ React.createElement(E404, {
      ...props
    });
  }
  return /* @__PURE__ */ React.createElement(E500, {
    ...props
  });
}
function E404(props) {
  return /* @__PURE__ */ React.createElement(Header, null, /* @__PURE__ */ React.createElement(Headline, null, t("Hier gibt es leider nichts zu sehen")), /* @__PURE__ */ React.createElement(Text, {
    dangerouslySetInnerHTML: {
      __html: t("Wenn du denkst, dass dies ein Fehler ist, kontaktiere uns gerne {{- link }}", {
        link: '<a href="mailto:service@printmate.de">service@printmate.de</a>'
      })
    }
  }), /* @__PURE__ */ React.createElement(Button, {
    href: "/"
  }, t("Zurück zur Startseite")));
}
function E500(props) {
  const {code, type, message, url} = props;
  const emailBody = encodeURI(`
Folgender Fehler ist aufgetreten:

${code}
${type}
${message}

URL: ${url}
`);
  return /* @__PURE__ */ React.createElement(Header, null, /* @__PURE__ */ React.createElement(Headline, null, t("Oh Oh - ein unbekannter Fehler ist aufgetreten!")), /* @__PURE__ */ React.createElement(Text, {
    dangerouslySetInnerHTML: {
      __html: t("Bitte kontaktiere uns mit einem Klick auf {{- link }}, damit wir dir umgehend weiterhelfen können!", {
        link: `<a href="mailto:service@printmate.de?body=${emailBody}&subject=Fehler: ${code} - ${message}">service@printmate.de</a>`
      })
    }
  }), /* @__PURE__ */ React.createElement(Button, {
    href: "/"
  }, t("Zur Startseite")));
}
const Main = styled(withAttrs(Stack, {spacing: "xxl"}))`
  padding: 100px 0;
  ${media.phone} {
    --spacing: 16px;
    padding: 50px 0;
  }
`;
const Header = styled(withAttrs(Stack, {spacing: "l"}))`
  align-items: center;

  ${media.phone} {
    --spacing: 24px;
  }
`;
const Headline = styled(Title)`
  font-size: 38px;

  ${media.phone} {
    font-size: 20px;
  }
`;
const Text = styled.div`
  font-family: ${fontFamily.primary};
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;

  a {
    color: inherit;
  }

  ${media.phone} {
    font-size: 14px;
  }
`;
const AdditionalText = styled(Text)`
  margin-top: 50px;
`;
const Img = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
`;
const Code = styled(Stack)`
  --spacing: 5px;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid ${colors.b5};
  background: ${colors.b6};
  font-family: Courier New;

  code {
  }
`;
